import React, { createContext, useState, useContext, useEffect } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        checkAuth();
    }, []);

    const checkAuth = async () => {
        try {
            const response = await fetch("http://localhost:8000/src/auth/check_auth.php", {
                credentials: "include",
            });

            const data = await response.json();
            if (data.authenticated) {
                setUser(data.user);
            }
        } catch (error) {
            console.error('Erreur de vérification de l\'authentification:', error.message);
        } finally {
            setLoading(false);
        }
    };

    const login = async (credentials) => {
        try {
            const response = await fetch("http://localhost:8000/api/login.php", {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(credentials),
            });

            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                const data = await response.json();
                if (data.success) {
                    setUser(data.user);
                    return true;
                }
                } else {
                // Si ce n'est pas du JSON, affichez le contenu pour le débogage
                const text = await response.text();
                console.error('Réponse non-JSON reçue:', text);
                }
                return false;
            } catch (error) {
                console.error('Erreur de connexion:', error);
                return false;
            }
};

    return (
        <AuthContext.Provider value={{ user, loading, login }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);