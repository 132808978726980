import React, { createContext, useState, useEffect, useCallback } from "react";

//Création du contexte
const UserContext = createContext();

//Exportation du provider qui va permettre d'utiliser le contexte
export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const checkUserSession = useCallback(async () => {
        try {
            const response = await fetch("http://localhost:8000/api/checkSession.php", {
                method: "GET",
                credentials: "include",
            });
            const data = await response.json();
            if (data.success && data.user) {
                setUser(data.user);
            } else {
                setUser(null);
            }
        } catch (error) {
            console.error('Erreur lors de la vérification de la session:', error);
            setUser(null);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        checkUserSession();
    }, [checkUserSession]);

    const logout = async () => {
        try {
            await fetch("http://localhost:8000/api/logout.php", {
                method: "POST",
                credentials: "include",
            });
            setUser(null);
            // Rediriger vers la page de connexion ou la page d'accueil
            window.location.href = '/login';
        } catch (error) {
            console.error('Erreur lors de la déconnexion:', error);
        }
    };

    // Fonction pour vérifier l'inactivité
    useEffect(() => {
        let inactivityTimer;

        const resetInactivityTimer = () => {
            clearTimeout(inactivityTimer);
            inactivityTimer = setTimeout(() => {
                logout();
            }, 30 * 60 * 1000); // 30 minutes
        };

        // Réinitialiser le timer à chaque activité
        window.addEventListener('mousemove', resetInactivityTimer);
        window.addEventListener('keydown', resetInactivityTimer);

        // Initialiser le timer
        resetInactivityTimer();

        return () => {
            clearTimeout(inactivityTimer);
            window.removeEventListener('mousemove', resetInactivityTimer);
            window.removeEventListener('keydown', resetInactivityTimer);
        };
    }, []);

    useEffect(() => {
        fetch("http://localhost:8000/api/infosUser.php", {
            method: "GET",
            credentials: "include",
        })
        .then(response => response.json())
        .then(data => {
            console.log("Données reçues du backend :",data);
            if (data.success !== false) {
                setUser(data.user);
            } else {
                console.error('Erreur :', data.error);
            }
            setLoading(false);
        }) 
        .catch(error => {
            console.error('Erreur :', error);
            setLoading(false);
        });
}, []);

    return (
        <UserContext.Provider value={{ user, setUser, loading, logout, checkUserSession }}>
            {children}
        </UserContext.Provider>
    );
};

// Export explicite 
export { UserContext };

// export default UserContext;