// Importation des dependances nécessaires
//React et ses Hook
import React, { useContext, useState, useEffect } from "react";
//Importation du contexte utilisateur
import { UserContext } from "../hooks/UserContext";
//Importation du style de CardUser
import "../scss/CardUser.scss";
//Importation de Link pour les liens de navigation
import { Link } from "react-router-dom";
//Importation des images
import bin from "../assets/bin.svg";
import edit from "../assets/edit.svg";
//Importation des fonctions de date et de format de la librairie date-fns
import { format, addDays, getDate, parse } from "date-fns";
//Importation fr pour les dates en français
import { fr } from "date-fns/locale/fr";
//Importation de la librairie date-holidays pour les jours fériés
import Holidays from "date-holidays";

const CardUser = () => {
    // Utilisation du contexte utilisateur pour accéder aux données et fonctions globales
    const { user, setUser, loading, logout } = useContext(UserContext);
    // État pour stocker les rendez-vous
    const [appointments, setAppointments] = useState([]);
    // État pour gérer l'édition c'est à dire pour modifier les rendez-vous
    const [editingAppointment, setEditingAppointment] = useState(null);
    // États pour stocker les services
    const [services, setServices] = useState([]);
    // États pour gérer la selection de la date et l'heure du rendez-vous
    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedDay, setSelectedDay] = useState('');
    const [selectedTime, setSelectedTime] = useState('');
    // États pour stocker les jours et heures disponibles
    const [availableDays, setAvailableDays] = useState([]);
    const [availableTimes, setAvailableTimes] = useState([]);
    // États pour afficher le méssage s'il n'y a pas de créneaux disponibles 
    const [noSlotsMessage, setNoSlotsMessage] = useState('');

    // Fonction pour formater la date en toutes lettres
    // Exemple : Lundi 14 octobre 2024 au lieu de 14-10-2024
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const formatted = date.toLocaleDateString('fr-FR', {
            weekday: 'long', // Jour de la semaine en toutes lettres
            year: 'numeric', // Annee en chiffres
            month: 'long', // Mois en toutes lettres
            day: '2-digit' // Jour du mois sur 2 chiffres
          });
          // Mettre la première lettre en majuscule
          return formatted.charAt(0).toUpperCase() + formatted.slice(1);
    };
    // Liste des mois pour la selection
    const months = ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Decembre'];
    // Effet pour générer les jours disponibles du mois sélectionné
    useEffect(() => {
        const holidays = new Holidays('FR'); // Utilisation de la librairie date-holidays pour les jours fériés en France
        const days = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi']; // Liste des jours de la semaine travaillés par le client (Ici le Kinésithérapeute).
        // Fonction pour savoir si un jour est un jour férié
        function isHoliday(date) {
            return holidays.isHoliday(date);
        }
        if (selectedMonth) {
            const currentYear = new Date().getFullYear();
            // Créer une date pour le premier jour du mois sélectionné
            const firstDayOfMonth = parse(`1 ${selectedMonth} ${currentYear}`, 'd MMMM yyyy', new Date(), { locale: fr });
            const daysInMonth = [];
            // Bouclepour parcourir tous les jours du mois
            for (let i = 0; i < 31; i++) {
                const day = addDays(firstDayOfMonth, i);
                if (day.getMonth() === firstDayOfMonth.getMonth()) {
                    const dayName = format(day, 'EEEE', { locale: fr });
                    // Si le jour correspond à un jour travaillé, et que le jour n'est pas un jour férié alors il est ajouté au tableau daysInMonth.
                    if (days.includes(dayName) && !isHoliday(day)) {
                        daysInMonth.push({
                            name: dayName,
                            date: getDate(day),
                            fullDate: day
                        });
                    }
                } else {
                    break; // Sortir de la boucle si on atteint le mois suivant
                }
            }
            setAvailableDays(daysInMonth);
        }
    }, [selectedMonth]);

    // Fonction pour récuperer les créneaux disponibles pour la date selectionnée
    const fetchAvailableSlots = async (date) => {
        const formattedDate = format(date, 'yyyy-MM-dd');
        try {
            const response = await fetch(`http://localhost:8000/api/getSlots.php?date=${formattedDate}`, {
                method: "GET",
                credentials: "include",
            });
            if (!response.ok) {
                throw new Error("Erreur lors de la récupération des créneaux disponibles");
            }
            const data = await response.json();
            console.log('Data:', data);
            setAvailableTimes(data.availableSlots || []);
            setNoSlotsMessage(data.message || '');
        } catch (error) {
        console.error('Error:', error);
        setAvailableTimes([]);
        setNoSlotsMessage('Erreur lors de la récupération des créneaux');
        }
    };

    // Effet pour récupérer les créneaux disponibles pour la date selectionnée
    useEffect(() => {
        if (selectedDay) {
            const formattedDate = format(selectedDay.fullDate, 'yyyy-MM-dd');
            fetchAvailableSlots(formattedDate);
        }
    }, [selectedDay]);

    // Effet pour mettre à jour les rendez-vous si l'utilisateur change
    useEffect(() => {
        console.log("user:", user);
        if (user && user.appointments) {
            console.log("Appointments:", user.appointments);
            setAppointments(user.appointments);
        }
    }, [user]);
    
    // Fonction pour récupérer les actes médicaux depuis l'API
    const fetchServices = async () => {
        try {
            const response = await fetch('http://localhost:8000/api/getServices.php', {
                credentials: 'include'
            });
            const data = await response.json();
            if (data.success) {
                setServices(data.services);
            } else {
                console.error('Erreur lors du chargement des actes médicaux:', data.error);
            }
        } catch (error) {
            console.error('Erreur lors du chargement des actes médicaux:', error);
        }
    };

    // Effet pour charger les actes médicaux
    useEffect(() => {
        fetchServices();
    }, []);

    //Fonction pour supprimer un rendez-vous
    const handleDeleteAppointment = async (appointmentId) => {
        console.log("Rendez-vous à supprimer :", appointmentId);
        if (window.confirm("Voulez-vous supprimer ce rendez-vous ?")) {
            try {
                const response = await fetch(`http://localhost:8000/api/deleteAppointment.php`, {
                    method: "POST",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ id: appointmentId }),
                });
                
                const responseText = await response.text();
                console.log("Response text:", responseText);
    
                let data;
                try {
                    data = JSON.parse(responseText);
                } catch (e) {
                    console.error("Failed to parse JSON:", e);
                    throw new Error("La réponse du serveur n'est pas un JSON valide");
                }
    
                if (!response.ok) {
                    throw new Error(data.error || "Erreur lors de la suppression du rendez-vous");
                }
    
                if (data.success) {
                    // Mettre à jour la liste des rendez-vous et l'état de l'utilisateur
                    const updatedAppointments = appointments.filter(app => app.id !== appointmentId);
                    setAppointments(updatedAppointments);
                    setUser(prevUser => ({
                        ...prevUser,
                        appointments: prevUser.appointments.filter(app => app.id !== appointmentId)
                    }));
                } else {
                    throw new Error(data.error || "Erreur inconnue");
                }
            } catch (error) {
                console.error('Error:', error);
                alert(error.message);
            }
        }
    };
    // Fonction initialiser la modification d'un rendez-vous
    const handleUpdateAppointment = (appointment) => {
        setEditingAppointment(appointment);
        setSelectedMonth('');
        setSelectedDay('');
        setSelectedTime('');
    };

    // Fonction soumettre la modification d'un rendez-vous
    const submitUpdateAppointment = async () => {
        // Vérification des informatations nécessaires à la modification
        if (!editingAppointment || !selectedDay || !selectedTime) {
            alert("Veuillez sélectionner une date et une heure valides.");
            return;
        }
        console.log("Rendez-vous à modifier :", editingAppointment);
        console.log("Nouvelle date et heure :", selectedDay.fullDate, selectedTime);
    
        try {
            // Formatage de la date au format yyyy-MM-dd
            const formattedDate = format(selectedDay.fullDate, 'yyyy-MM-dd');
            
            // Formatage de l'heure au format hh:mm:ss
            const formattedTime = selectedTime.includes('h') 
                ? selectedTime.replace('h', ':00:00') 
                : selectedTime + ':00';
    
            console.log("Nouvelle date et heure :", formattedDate, formattedTime);
            // Envoi de la requête pour la mise à jour au serveur
            const response = await fetch(`http://localhost:8000/api/updateAppointment.php`, {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    id: editingAppointment.id,
                    date_app: formattedDate,
                    hours_app: formattedTime,
                    medical_act: editingAppointment.medical_act
                }),
            });
    
            const data = await response.json();
    
            if (data.success) {
                // Mise à jour locale des rendez-vous
                const updatedAppointments = appointments.map(app =>
                    app.id === editingAppointment.id
                        ? { ...app, date_app: formattedDate, hours_app: formattedTime }
                        : app
                );
                setAppointments(updatedAppointments);
                // Mise à jour de l'état de l'utilisateur  
                setUser(prevUser => ({
                    ...prevUser,
                    appointments: updatedAppointments
                }));
                // Fermeture de la fenêtre réinitialisation des états de modification
                setEditingAppointment(null);
                setSelectedMonth('');
                setSelectedDay('');
                setSelectedTime('');
                alert("Rendez-vous modifié avec succès");
            } else {
                throw new Error(data.error || "Erreur lors de la mise à jour du rendez-vous");
            }
        } catch (error) {
            console.error('Error:', error);
            alert(error.message);
        }
    };
    // Effet pour récupérer les créneaux disponibles lorsqu'une date est sélectionnée
    useEffect(() => {
        if (selectedDay) {
            const selectedDayObj = availableDays.find(d => d.name === selectedDay);
            if (selectedDayObj) {
            const formattedDate = format(selectedDayObj.fullDate, 'yyyy-MM-dd');
            fetchAvailableSlots(formattedDate);
            }
        }
    }, [selectedDay, availableDays]);
        // Affichage pendant le chargement des informations de l'utilisateur
        if (loading) {
            return <div>Chargement des informations utilisateur...</div>;
        }
        // Affichage si aucune information utilisateur n'est disponible
        if (!user || !user.lastname) {
            return <div>Aucune information utilisateur disponible.</div>;
        } 

    return (
        <div className="cardUser">
            <h2>Espace privé de :</h2> 
            <h4>{user.firstname} {user.lastname}</h4>
            <p>Téléphone : {user.phone_number}</p>
            <p>Email : {user.email}</p>
            
            <h4>Vos rendez-vous :</h4>
            <ul className="list_appointments">
                {user.appointments && user.appointments.length > 0 ? (
                    user.appointments
                    // Trier les rendez-vous par date
                    .sort((a, b) => new Date(a.date_app) - new Date(b.date_app))
                    .map((appointment) => (
                        <li className="rdv" key={appointment.id}>
                            <button onClick={() => handleDeleteAppointment(appointment.id)} className="btnBin">
                                <img src={bin} alt="Supprimer" title="Supprimer ce rendez-vous" className="imgBin" />
                            </button>
    
                            <button onClick={() => handleUpdateAppointment(appointment)} className="btnEdit">
                                <img src={edit} alt="Modifier" title="Modifier ce rendez-vous" className="imgEdit" />
                            </button>
    
                            Le {formatDate(appointment.date_app)} à {appointment.hours_app.replace(':00:00', 'h00')} pour {appointment.medical_act} 
                        </li>
                    ))
                ) : (
                    <li>Aucun rendez-vous.</li>
                )}
            </ul>
            {/* Formulaire pour la modification d'un rendez-vous */}
            {editingAppointment && (
                <div className="editAppointmentForm">
                    <h3>Modifier le rendez-vous</h3>
                    <div className="formEdit">
                        <div className="dateSelection">
                            <select
                                value={selectedMonth}
                                onChange={(e) => {
                                    setSelectedMonth(e.target.value);
                                    setSelectedDay('');
                                    setSelectedTime('');
                                }}
                            >
                                <option value="">Sélectionnez un mois</option>
                                {months.map(month => (
                                    <option key={month} value={month}>{month}</option>
                                ))}
                            </select>
                            {selectedMonth && (
                                <select
                                    value={selectedDay ? `${selectedDay.name}-${selectedDay.date}` : ''}
                                    onChange={(e) => {
                                        const [name, date] = e.target.value.split('-');
                                        setSelectedDay(availableDays.find(day => day.name === name && day.date === parseInt(date)));
                                        setSelectedTime('');
                                    }}
                                >
                                    <option value="">Sélectionnez un jour</option>
                                    {availableDays.map((day) => (
                                        <option key={`${day.name}-${day.date}`} value={`${day.name}-${day.date}`}>
                                            {day.name.charAt(0).toUpperCase() + day.name.slice(1)} {day.date}
                                        </option>
                                    ))}
                                </select>
                            )}
                        </div>
                        {selectedDay && (
                            <div className="timeSelection">
                                {availableTimes.length > 0 ? (
                                    <select
                                        value={selectedTime}
                                        onChange={(e) => setSelectedTime(e.target.value)}
                                    >
                                        <option value="">Sélectionnez une heure</option>
                                        {availableTimes.map((time) => (
                                            <option key={time} value={time}>{time}</option>
                                        ))}
                                    </select>
                                ) : (
                                    <p>{noSlotsMessage || "Aucun créneau disponible pour ce jour."}</p>
                                )}
                                <select
                                    value={editingAppointment.medical_act || ''}
                                    onChange={(e) => setEditingAppointment({...editingAppointment, medical_act: e.target.value})}
                                >
                                    {services.map((service) => (
                                        <option key={service.id} value={service.name_medical_act}>{service.name_medical_act}</option>
                                    ))}
                                </select>
                            </div>
                        )}
                        <div className="btnContainer">
                            <button onClick={submitUpdateAppointment}>Confirmer la modification</button>
                            <button onClick={() => setEditingAppointment(null)}>Annuler</button>
                        </div>
                    </div>
                </div>
            )}
    
            <div className="btnContainer">
                <Link to='/login'>
                    <button onClick={logout}>Se déconnecter</button>
                </Link>
            </div>
        </div>
    );  
}           

export { CardUser };

export default CardUser;