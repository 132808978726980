import React, { useState } from 'react';
import '../scss/Header.scss';
import logo from '../assets/logoKine.png';
import cross from '../assets/cross.svg';
import burger from '../assets/burger.svg';
import iconUser from '../assets/loguser.svg';
import { Link } from 'react-router-dom';

function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
         <div className={`header ${isMenuOpen ? 'menu-open' : ''}`}>
            <div className="header-container">
                <Link to='/' className="logo">
                    <img src={logo} alt="logo du cabinet de kinésithérapie" />
                </Link>
                <nav className="menu-list">
                    <ul>
                        <li><Link to="/">Accueil</Link></li>
                        <li><Link to="/about">A propos</Link></li>
                        <li><Link to="/services">Services</Link></li>
                        <li><Link to="/blog">Blog</Link></li>
                        <li><Link to="/contact">Contact</Link></li>
                        <li><Link to="/login"><img src={iconUser} alt="logo de l'utilisateur" className="icon-user-mobile" title="Connexion" /></Link></li>
                    </ul>  
                </nav>
                <Link to="/login"><img src={iconUser} alt="logo de l'utilisateur" className="icon-user-desktop" title="Connexion" /></Link>
            </div>
            <div className="menu-mobile">
                <img 
                    src={isMenuOpen ? cross : burger} 
                    alt={isMenuOpen ? "icone de fermeture du menu" : "icone d'ouverture du menu"}
                    className={isMenuOpen ? 'cross' : 'burger'}
                    onClick={toggleMenu}
                />
            </div>
        </div>
    );
}

export default Header;