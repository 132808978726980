import React from 'react';
import '../scss/BtnRdv.scss';
import { Link } from 'react-router-dom';


function BtnRdv() {
    // console.log('BtnRdv test retour');
    return (
        <Link to='/login'>
            <button className='BtnRdv'>Prendre rendez-vous</button>
        </Link>
        
    );
}

export default BtnRdv;