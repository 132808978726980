import React, { useContext, useState, useEffect } from "react";
import "../scss/Appointment.scss";
import Header from "../components/Header";
import Footer from "../components/Footer";  
import Breadcrumb from "../components/Breadcrumb";
import { Link } from "react-router-dom";
import CardUser from "../components/CardUser";
import { UserContext } from "../hooks/UserContext";
import { format, addDays, getDate, parse } from "date-fns";
import { fr } from "date-fns/locale/fr";
import Holidays from "date-holidays";

function Appointment() {
    const { user, loading } = useContext(UserContext);
    const [selectedService, setSelectedService] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedDay, setSelectedDay] = useState('');
    const [selectedTime, setSelectedTime] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [submitError, setSubmitError] = useState('');
    const [availableTimes, setAvailableTimes] = useState([]);
    

    // États pour les services
    const [services, setServices] = useState([]);
    const [servicesLoading, setServicesLoading] = useState(true);
    const [servicesError, setServicesError] = useState('');

    // États pour les Slots
    const [noSlotsMessage, setNoSlotsMessage] = useState('');

    // Récupérer les services depuis le backend
    useEffect(() => {
        const fetchServices = async () => {
            try {
                const response = await fetch("http://localhost:8000/api/getServices.php", {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                if (!response.ok) {
                    throw new Error("Erreur lors de la récupération des services");
                }
                const data = await response.json();
                if (data.success) {
                    setServices(data.services);
                    setServicesError('');
                } else {
                    setServicesError(data.error || "Erreur inconnue");
                }
            } catch (error) {
                console.error('Error:', error);
                setServicesError(error.message);
            } finally {
                setServicesLoading(false);
            }
        };
        fetchServices();
    }, []);

    const fetchAvailableSlots = async (date) => {
        try {
            const response = await fetch(`http://localhost:8000/api/getSlots.php?date=${date}`, {
                method: "GET",
                credentials: "include",
            });
            if (!response.ok) {
                throw new Error("Erreur lors de la récupération des créneaux disponibles");
            }
            const data = await response.json();
            console.log('Data:', data);
            setAvailableTimes(data.availableSlots || []);
            setNoSlotsMessage(data.message || '');
          } catch (error) {
            console.error('Error:', error);
            setAvailableTimes([]);
            setNoSlotsMessage('Erreur lors de la récupération des créneaux');
          }
        };

    useEffect(() => {
      if (selectedDay) {
          const formattedDate = format(selectedDay.fullDate, 'yyyy-MM-dd');
          fetchAvailableSlots(formattedDate);
      }
  }, [selectedDay]);


    // Fonction de soumission
    const submitAppointment = async () => {
        console.log("État de l'user : ", user);
        if (!user || !user.id) {
            setSubmitError('Veuillez vous connecter pour prendre un rendez-vous.');
            return;
        }
        setIsSubmitted(true);
        setSubmitError('');
        try {
            const selectedDayObj = selectedDay;
        if (!selectedDayObj) {
            throw new Error("Jour sélectionné invalide.");
        }
        const formattedDate = format(selectedDayObj.fullDate, 'yyyy-MM-dd');
        const formattedTime = selectedTime.replace('h', ':00:00');
        console.log("Données envoyées au backend : ", {
            user_id: user.id,
            medical_act_id: selectedService.id,
            service: selectedService.name_medical_act,
            date_app: formattedDate,
            hours_app: formattedTime,
            status:'confirmed'
        });
        const response = await fetch("http://localhost:8000/api/appointment.php", {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                user_id: user.id,
                medical_act_id: selectedService.id,
                service: selectedService.name_medical_act,
                date_app: formattedDate,
                hours_app: formattedTime,
                status:'confirmed'
            }),
        });

        const responseText = await response.text();
        console.log('Response text:', responseText);
        let data;
        try {
            data = JSON.parse(responseText);
        } catch (e) {
            console.error('Réponse non-JSON reçue:', responseText);
            throw new Error("La réponse du serveur n'est pas un JSON valide");
        }

        if (!response.ok) {
            throw new Error(data.error || "Une erreur est survenue");
        }

        if (data.success) {
            setSubmitError('');
            window.location.reload();
        } else {
            setSubmitError(data.error || "Une erreur inconnue est survenue");
        }

        console.log('Rendez-vous enregistré avec succès : ', data);
        alert('Rendez-vous enregistré avec succès !');
    } catch (error) {
        console.error('Error:', error);
        setSubmitError(error.message);
    } finally {
        setIsSubmitted(false);
    }
};

    // États pour les rendez-vous
    const months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
    
    const [availableDays, setAvailableDays] = useState([]);

    useEffect(() => {
        const holidays = new Holidays('FR');
        const days = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi'];
        function isHoliday(date) {
            return holidays.isHoliday(date);
        }
        if (selectedMonth) {
            const currentYear = new Date().getFullYear();
            const firstDayOfMonth = parse(`1 ${selectedMonth} ${currentYear}`, 'd MMMM yyyy', new Date(), { locale: fr });
            const daysInMonth = [];

            for (let i = 0; i < 31; i++) {
                const day = addDays(firstDayOfMonth, i);
                if (day.getMonth() === firstDayOfMonth.getMonth()) {
                    const dayName = format(day, 'EEEE', { locale: fr });
                    if (days.includes(dayName) && !isHoliday(day)) {
                        daysInMonth.push({
                            name: dayName,
                            date: getDate(day),
                            fullDate: day
                        });
                    }
                } else {
                    break;
                }
            }
    
            setAvailableDays(daysInMonth);
        }
    }, [selectedMonth]);
        
    useEffect(() => {
            if (selectedDay) {
                const selectedDayObj = availableDays.find(d => d.name === selectedDay);
                if (selectedDayObj) {
                  const formattedDate = format(selectedDayObj.fullDate, 'yyyy-MM-dd');
                  fetchAvailableSlots(formattedDate);
                }
              }
            }, [selectedDay, availableDays]);

    const getDayNumber = (day) => {
        return day ? day.date : '';
    };

    if (loading || servicesLoading) {
        return <div>Chargement...</div>;
    }

    const paths = [
        { name: "Accueil", link: "/" },
        { name: "Prendre rendez-vous", link: "/appointment" },
    ]; 

    console.log("Mois sélectionné :", selectedMonth);
    console.log("Jour sélectionné :", selectedDay);
    console.log("Numéro du jour sélectionné :", getDayNumber(selectedDay));
    console.log("Service sélectionné :", selectedService);

    return (
        <div>
            <Header />
            <div className="fil_ariane">
              <Breadcrumb paths={paths}/>  
            </div>
            <div className="appointment">
                <h1>Prendre rendez-vous</h1>
                {user ? (
                    <CardUser />
                ) : (
                    <div>Aucune information utilisateur disponible.</div>
                )}
                <h2>Choisissez votre soin</h2>
                <div className="BtnContainer">
                    {servicesError ? (
                        <p className="error">{servicesError}</p>
                    ) : (
                    services.map(service => (
                        <button
                            key={service.id}
                            className={`Btn${service.name_medical_act.replace(/\s+/g, "")}`}
                            onClick={() => setSelectedService(service)}
                            
                        >
                            {service.name_medical_act}
                        </button>
                    ))
                    )}
                </div>

                {selectedService && (
                    <>
                        <h2>Choisissez le mois de votre rendez-vous</h2>
                        <div className="BtnContainer">
                            {months.map(month => (
                                <button 
                                    key={month} 
                                    onClick={() => {
                                        setSelectedMonth(month);
                                        setSelectedDay('');
                                        setSelectedTime('');
                                    }}
                                >
                                    {month}
                                </button>
                            ))}
                        </div>
                    </>
                )}

                {selectedMonth && (
                    <>
                        <h2>Choisissez le jour de votre rendez-vous</h2>
                        <div className="BtnContainer">
                            {availableDays.map(( day ) => (
                                <button 
                                    key={`${day.name}-${day.date}`} 
                                    onClick={() => setSelectedDay(day)}
                                >
                                    {day.name.charAt(0).toUpperCase() + day.name.slice(1)} {day.date}
                                </button>
                            ))}
                        </div>
                    </>
                )}

                {selectedDay && (
                    <>
                        <h2>Choisissez l'heure de votre rendez-vous</h2>
                        <div className="BtnContainer">
                            {availableTimes.length > 0 ? (
                                console.log('créneaux disponibles : ',availableTimes),
                                availableTimes.map((time) => (
                                <button
                                    key={time}
                                    onClick={() => setSelectedTime(time)}
                                    className={selectedTime === time ? 'selected' : ''}
                                >
                                    {time}
                                </button>
                                ))
                            ) : (
                                <p>{noSlotsMessage || "Aucun créneau disponible pour ce jour."}</p>
                            )}
                        </div>
                    </>
                )}

                {selectedService && selectedMonth && selectedDay && selectedTime && (
                    <div>
                        <h3>Récapitulatif :</h3>
                        <div className="recap">
                            <p>Soin : {selectedService.name_medical_act}</p>
                            <p>Mois : {selectedMonth}</p>
                            <p>Jour : {selectedDay ? `${selectedDay.name.charAt(0).toUpperCase() + selectedDay.name.slice(1)} ${selectedDay.date}` : ''}</p>
                            <p>Heure : {selectedTime}</p> 
                        </div>                       
                        <div className="BtnContainer">
                            <button className="BtnContact" onClick={submitAppointment} disabled={isSubmitted}>
                                {isSubmitted ? 'En cours...' : 'Je valide mon rendez-vous'}
                            </button>
                            {submitError && <p>{submitError}</p>}
                        </div>
                    </div>
                )}

                <div>
                    <h4>Un problème ? Une question ?</h4>
                    <Link to="/contact">
                        <button className="BtnContact">Contactez-nous</button>
                    </Link>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Appointment;