import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import "../scss/Cookie.scss";

const Cookie = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['cookieConsent']);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        // Vérifier si le cookie de consentement existe
        if (cookies.cookieConsent === undefined) {
            setIsVisible(true);
        }
    }, [cookies.cookieConsent]);

    const handleAccept = () => {
        setCookie("cookieConsent", "true", { path: "/", maxAge: 3600});
        setIsVisible(false);
    };

    const handleRefuse = () => {
        removeCookie("cookieConsent", { path: "/" });
        setIsVisible(false);
    };

    if (!isVisible) return null;

    return (
        <div className="cookie">
            <p>
                Ce site utilise des cookies pour améliorer votre expérience. 
                En continuant votre navigation, vous acceptez l'utilisation de ces derniers.
            </p>
            <button className="cookie_btn accept" onClick={handleAccept}>Accepter</button>
            <button className="cookie_btn refuse" onClick={handleRefuse}>Refuser</button>
        </div>
    );
};

export default Cookie;