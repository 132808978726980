import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../scss/Login.scss';
import { useAuth } from '../hooks/AuthContext';
import { UserContext } from '../hooks/UserContext';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();
  const { setUser } = useContext(UserContext);
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const success = await login({ email, password });
      if (success) {
        console.log('Connexion réussie');

        const response = await fetch('http://localhost:8000/api/infosUser.php', {
          method: 'GET',
          credentials: 'include',
        });
        const data = await response.json();

        if (data.success !== false) {
          setUser(data.user);
        }
        navigate('/appointment');
      } else {
        setError('Échec de la connexion. Vérifiez vos identifiants.');
      }
    } catch (error) {
      console.error('Erreur complète:', error);
      setError('Une erreur est survenue lors de la connexion: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Header />
      <div className="form_login">
        <h3>Connexion</h3>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        
        <form onSubmit={handleSubmit}>
          <div>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder='Votre email'
              required
            />
          </div>
          <div>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder='Votre mot de passe'
              required
            />
          </div>
          <button type="submit" disabled={loading}>
            {loading ? 'Connexion...' : 'Se connecter'}
          </button>
        </form>
        <p>Vous n'avez pas de compte ? <Link to="/register">Inscrivez-vous</Link></p>
      </div>
      <Footer />
    </div>
  );
}

export default Login;