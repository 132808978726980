import React from 'react';
import { Link } from 'react-router-dom';
import '../scss/NotFound.scss';
import returnHome from '../assets/home.svg';

const NotFound = () => {
  return (
    <div className="not-found">
        <div>
            <h1>404 - Page Not Found</h1>
            <p>La page que vous recherchez n’existe pas.</p>
            <h4>Retourner sur la page d’accueil ?</h4>
            <Link to='/' className="returnHome">
                    <img src={returnHome} alt="Retour sur la page d'accueil" />
            </Link>
        </div>
    </div>
  );
};

export default NotFound;